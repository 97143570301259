.App {
  text-align: center;
}

::-webkit-scrollbar {
  height: 12px;
  width: 4px;
  background: rgb(15, 8, 25);
}

::-webkit-scrollbar-thumb {
  background: #25222e;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}
